.image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    margin-bottom: 10vh;
    max-width: 50%;
    height: 55vh;
    border-radius: max(1vw, 1vh);
    animation-name: fade;
    animation-duration: 4s;
    object-fit: contain;
    position: relative;
    z-index: 200;
}

@keyframes fade {
    from {opacity: 0;}
    to {opacity: 1;}
}

.background-img {
    width: 100%;
    height: 100vh;
    left: 0px;
    right: 0px;
    top: 0px;
    position: fixed;
    z-index: 100;
}

@media (max-width: 800px) {
    .background-img {
        left: -80vw;
        width: 200%;
    }

    .image {
        margin-top: 12vw;
        margin-bottom: -5vw;
    }
}