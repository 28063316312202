.utk-picture {
    display: flex;
    margin-left: 48%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.utk-school {
    font-size: 50px;
    margin-left: 38%;
}

.utk-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .utk-picture {
        margin-left: 35%;
    }

    .utk-school {
        margin-left: 33%;
    }

    .utk-text {
        margin-left: 30%;
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .utk-picture {
        margin-left: 51%;
    }
    .utk-school {
        margin-left: 31%;
    }
}