.background {
    background-color: gray;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.download {
  text-align: center;
    position: relative;
    font-size: 50px;
    margin-top: 120px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    z-index: 200;
}

/* Design taken from CSS Scan by Algolia*/

.button {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.resume {
    position: relative;
    display: block;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border: 1px solid black;
    z-index: 200;
}

@media (max-width: 1600px) {
  .resume {
    width: 60%
  }
}

@media (max-width: 1400px) {
  .resume {
    width: 70%
  }
}

@media (max-width: 1200px) {
  .resume {
    width: 80%
  }
}

@media (max-width: 1000px) {
  .resume {
    width: 90%
  }
}

@media (max-width: 800px) {
  .resume {
    width: 100%
  }
}