.side-bar {
    height: 100%;
    width: 14vw;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    padding-top: 10vh;
    padding-left: env(safe-area-inset-left);
    background-color: rgb(32, 32, 32);
    overflow-x: hidden;
    font-size: small;
}

/* Design taken from CSS Scan by Tailwind*/

.buttons {
  background-color: rgb(32, 32, 32);
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 0rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.buttons:hover {
    color: #474b4f;
}

.buttons:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 800px) {
  .buttons {
    padding: .75rem 1rem;
  }
}

@media (max-width: 800px) {
  .side-bar {
    width: 20vw;
  }

  .buttons {
    font-size: small;
  }
}

@media (max-height: 500px) {
  .side-bar {
    width: 17vw;
    padding-left: 2vw;
  }
}