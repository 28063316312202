.cpa-picture {
    display: flex;
    margin-left: 48%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.cpa-school {
    font-size: 50px;
    margin-left: 41%;
}

.cpa-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .cpa-picture {
        margin-left: 34%;
    }

    .cpa-school {
        margin-left: 34%;
    }

    .cpa-text {
        margin-left: 30%;
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .cpa-picture {
        margin-left: 49%;
    }

    .cpa-school {
        margin-left: 35%;
    }
}