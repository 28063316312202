@import url(https://fonts.googleapis.com/css?family=VT323);

.sudoku {
    margin-left: 21vw;
    margin-top: 14vh;
    font-size: 5vw;
    font-family: 'Courier New', monospace;
}

.container {
    display: table;
    margin-left: 16vw;
    width: 80%;
    text-align: right;
    margin-bottom: -80px;
}

.sudoku-desc {
    display: table-cell;
    font-family: 'Courier New', monospace;
    font-size: .95vw;
    width: 28vw;
    text-align: justify;
}

.generate-grid {
    display: table-cell;
    white-space:pre;
    font-size: 1.83vw;
}

.solve-grid {
    display: table-cell;
    white-space:pre;
    font-size: 1.83vw;
}

/* Button design taken from Retro Buttons Website by b.Stayles */
.sudoku-button {
    position: relative;
	background: #7C7C7C;
	border-bottom: 6px inset rgba(0,0,0,.5);
	border-left: 6px inset rgba(0,0,0,.5);
	border-right: 6px inset rgba(255,255,255,.5);
	border-top: 6px inset rgba(255,255,255,.5);
	color: white;
	cursor: pointer;
	font-size: 1.4vw;
	margin-left: 5vw;
    margin-top: 3vh;
	width: 8vw;
	padding: .5vw;
	text-transform: uppercase;
    font-family: 'VT323', monospace ;
}

.solve-button:hover {
    background: #BCBCBC;
}

.easy-button {
	background: #0000BC;
    margin-left: 49%;
}

.easy-button:hover {
    background: #0000FC;
}

.medium-button {
	background: #005800;
}

.medium-button:hover {
    background: #006800;
}

.hard-button {
    background: #881400;
}

.hard-button:hover {
    background: #A81000;
}

@media (max-width: 1900px) {
    .sudoku-desc {
        padding-right: 5vw;
    }

    .generate-grid {
        padding-right: 5vw;
    }
}

@media (max-width: 800px) {
    .sudoku { 
        margin-left: 42vw;
        font-size: 10vw;
    }

    .container {
        table-layout: fixed;
        width: 250vw;
        margin-left: 28vw;
    }

    .sudoku-desc {
        font-size: 3vw;
        padding-right: 10vw;
        width: 60vw;
    }

    .generate-grid {
        font-size: 35px;
        padding-right: 10vw;
        margin-right: 10vw;
    }
    
    .solve-grid {
        font-size: 35px;
        padding-right: 10vw;
        margin-right: 10vw;
    }

    .sudoku-button {
        position: absolute;
        font-size: 7vw;
        width: 35vw;
        margin-top: 15vh;
    }

    .easy-button {
        left: 55vw;
        top: 80vh;
    }

    .medium-button {
        left: 145vw;
        top: 80vh;
    }

    .hard-button {
        left: 190vw;
        top: 80vh;
    }

    .solve-button {
        left: 235vw;
        top: 80vh;
    }
}

@media (max-height: 500px) {
    .sudoku { 
        margin-top: 17vh;
        margin-left: 26.5vw;
    }
    
    .container {
        margin-left: 22vw;
    }

    .sudoku-button { 
        left: 6vw;
        font-size: 1.2vw;
    }
}