.section {
    background-color: #1d2022;
    position: absolute;
    z-index: 300;
    padding-bottom: 5vh;
    width: 100%;
    left: 0;
    top: 85vh;
}

.about-me {
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: white;
    -webkit-text-stroke: .1vw black;
    text-align: center;
    padding-top: 7vh;
}

.text {
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    letter-spacing: .1vw;
    color: white;
    text-align: justify;
    padding-left: max(8vw, env(safe-area-inset-left));
    padding-right: max(8vw, env(safe-area-inset-right));
}
 
@media (max-width: 800px) {
    .section {
        top: 61vh;
    }

    .about-me {
        padding-top: 5vh;
        font-size: 4vw;
        -webkit-text-stroke: .1vw black;
    }
}