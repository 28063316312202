@import url(https://fonts.googleapis.com/css?family=VT323);

/* Design taken from CSS Scan by Michael McMillan*/

.page-link {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  letter-spacing: .1vw;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: .25vw solid;
  margin-right: .8vw;
  box-shadow: .01vw .01vw 0vw 0vw, .02vw .02vw 0vw 0vw, .1vw .1vw 0vw 0vw, .2vw .2vw 0vw 0vw, .3vw .3vw 0vw 0vw;
  position: relative;
  margin-bottom: .8vh;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.page-link:active {
  box-shadow: 0vw 0vw 0vw 0vw;
  top: .3vw;
  left: .3vw;
}

@media (min-width: 800px) {
  .page-link {
    padding: .4vh .5vw;
  }
}

@media (max-width: 800px) {
  .page-link {
    font-size: 2vw;
  }
}
.menu-bar {
    padding-top: max(3vh, env(safe-area-inset-top));
    padding-bottom: max(3vh, env(safe-area-inset-bottom));
    padding-left: max(3vh, env(safe-area-inset-left));
    padding-right: max(3vh, env(safe-area-inset-right));
    justify-content: space-between;
    background-color: #474b4f;
    top: 0px;
    left: 0px;
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 400;
    display: flex;
}

.my-name {
    position: absolute;
    right: max(8vw, env(safe-area-inset-right) + 8vw);
    top: 3vh;
    font-family: "Open Sans", sans-serif;
    font-size: 1.8vw;
    letter-spacing: .1vw;
    text-transform: uppercase;
    color: white;
    -webkit-text-stroke: .05vw black;
}

.version {
    font-size: .7vw;
    position: absolute;
    color: gray;
    top: 60%;
    z-index: 401;
    right: max(8vw, env(safe-area-inset-right) + 8vw);
}

@media (max-width: 1400px) {
    .version {
        font-size: 1vw;
    }
}

@media (max-width: 1200px) {
    .version {
        font-size: 1.3vw;
        right: max(8.5vw , env(safe-area-inset-right) + 8.5vw);
    }
}

@media (max-width: 1000px) {
    .version {
        font-size: 1.6vw;
        right: max(9vw, env(safe-area-inset-right) + 9vw);
    }
}

@media (max-width: 800px) {
    .menu-bar {
        padding-top: max(1vh, env(safe-area-inset-top));
        padding-bottom: max(1vh, env(safe-area-inset-bottom));
        padding-left: max(1vh, env(safe-area-inset-left));
        padding-right: max(1vh, env(safe-area-inset-right));
    }

    .my-name {
        position: relative;
        right: 0;
        top: .5vh;
        padding-right: max(3vw, env(safe-area-inset-right));
        font-size: 2.5vw;
    }

    .version {
        display: none;
    }
}
.side-bar {
    height: 100%;
    width: 14vw;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    padding-top: 10vh;
    padding-left: env(safe-area-inset-left);
    background-color: rgb(32, 32, 32);
    overflow-x: hidden;
    font-size: small;
}

/* Design taken from CSS Scan by Tailwind*/

.buttons {
  background-color: rgb(32, 32, 32);
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 0rem;
  text-align: center;
  -webkit-text-decoration: none #6B7280 solid;
          text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.buttons:hover {
    color: #474b4f;
}

.buttons:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 800px) {
  .buttons {
    padding: .75rem 1rem;
  }
}

@media (max-width: 800px) {
  .side-bar {
    width: 20vw;
  }

  .buttons {
    font-size: small;
  }
}

@media (max-height: 500px) {
  .side-bar {
    width: 17vw;
    padding-left: 2vw;
  }
}



.image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    margin-bottom: 10vh;
    max-width: 50%;
    height: 55vh;
    border-radius: max(1vw, 1vh);
    -webkit-animation-name: fade;
            animation-name: fade;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    object-fit: contain;
    position: relative;
    z-index: 200;
}

@-webkit-keyframes fade {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fade {
    from {opacity: 0;}
    to {opacity: 1;}
}

.background-img {
    width: 100%;
    height: 100vh;
    left: 0px;
    right: 0px;
    top: 0px;
    position: fixed;
    z-index: 100;
}

@media (max-width: 800px) {
    .background-img {
        left: -80vw;
        width: 200%;
    }

    .image {
        margin-top: 12vw;
        margin-bottom: -5vw;
    }
}
.section {
    background-color: #1d2022;
    position: absolute;
    z-index: 300;
    padding-bottom: 5vh;
    width: 100%;
    left: 0;
    top: 85vh;
}

.about-me {
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: white;
    -webkit-text-stroke: .1vw black;
    text-align: center;
    padding-top: 7vh;
}

.text {
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    letter-spacing: .1vw;
    color: white;
    text-align: justify;
    padding-left: max(8vw, env(safe-area-inset-left));
    padding-right: max(8vw, env(safe-area-inset-right));
}
 
@media (max-width: 800px) {
    .section {
        top: 61vh;
    }

    .about-me {
        padding-top: 5vh;
        font-size: 4vw;
        -webkit-text-stroke: .1vw black;
    }
}

.error {
    font-size: 50px;
    text-align: center;
    margin-top: 400px;
}
.background {
    background-color: gray;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.download {
  text-align: center;
    position: relative;
    font-size: 50px;
    margin-top: 120px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    z-index: 200;
}

/* Design taken from CSS Scan by Algolia*/

.button {
  align-items: center;
  -webkit-appearance: none;
          appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,-webkit-transform .15s;
  transition: box-shadow .15s,transform .15s;
  transition: box-shadow .15s,transform .15s,-webkit-transform .15s;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.button:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.resume {
    position: relative;
    display: block;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border: 1px solid black;
    z-index: 200;
}

@media (max-width: 1600px) {
  .resume {
    width: 60%
  }
}

@media (max-width: 1400px) {
  .resume {
    width: 70%
  }
}

@media (max-width: 1200px) {
  .resume {
    width: 80%
  }
}

@media (max-width: 1000px) {
  .resume {
    width: 90%
  }
}

@media (max-width: 800px) {
  .resume {
    width: 100%
  }
}
.cisco-picture {
    display: flex;
    margin-left: 47.5%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.cisco-company {
    font-size: 50px;
    margin-left: 53%;
}

.cisco-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .cisco-picture { 
        margin-left: 33%; 
    }

    .cisco-company {
        margin-left: 45%;
    }

    .cisco-text {
        margin-left: 30%; 
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .cisco-picture { 
        margin-left: 50vw;
    }

    .cisco-company {
        margin-left: 51.75%;
    }
}
.pilot-picture {
    display: flex;
    margin-left: 47%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.pilot-company {
    font-size: 50px;
    margin-left: 53%;
}

.pilot-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .pilot-picture {
        margin-left: 32%;
    }

    .pilot-company {
        margin-left: 46%;
    }

    .pilot-text {
        margin-left: 30%; 
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) { 
    .pilot-picture {
        margin-left: 50.5%;
    }
}
.parke-picture {
    display: flex;
    margin-left: 51%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.parke-company {
    font-size: 50px;
    margin-left: 45%;
}

.parke-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .parke-picture {
        margin-left: 36%;
    }

    .parke-company {
        margin-left: 36%;
    }

    .parke-text {
        margin-left: 30%;
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .parke-picture {
        margin-left: 51%;
    }

    .parke-company {
        margin-left: 33vw;
    }
}
.dd-picture {
    display: flex;
    margin-left: 46%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.dd-company {
    font-size: 50px;
    margin-left: 48%;
}

.dd-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .dd-picture {
        margin-left: 34%;
    }

    .dd-company {
        margin-left: 39%;
    }

    .dd-text {
        margin-left: 30%;
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .dd-company {
        margin-left: 38vw;
    }
}
.utk-picture {
    display: flex;
    margin-left: 48%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.utk-school {
    font-size: 50px;
    margin-left: 38%;
}

.utk-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .utk-picture {
        margin-left: 35%;
    }

    .utk-school {
        margin-left: 33%;
    }

    .utk-text {
        margin-left: 30%;
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .utk-picture {
        margin-left: 51%;
    }
    .utk-school {
        margin-left: 31%;
    }
}
.cpa-picture {
    display: flex;
    margin-left: 48%;
    margin-top: 20vh;
    margin-bottom: 8vh;
    max-width: 50%;
    height: 44vh;
    border-radius: 10px;
    position: relative;
    z-index: 199;
}

.cpa-school {
    font-size: 50px;
    margin-left: 41%;
}

.cpa-text {
    margin-top: 4vh;
    margin-bottom: 10vh;
    text-align: justify;
    margin-left: 25%;
    font-size: x-large;
    padding-right: 10vw;
}

@media (max-width: 800px) {
    .cpa-picture {
        margin-left: 34%;
    }

    .cpa-school {
        margin-left: 34%;
    }

    .cpa-text {
        margin-left: 30%;
        padding-right: 5vw;
        text-align: left;
        font-size: medium;
    }
}

@media (max-height: 500px) {
    .cpa-picture {
        margin-left: 49%;
    }

    .cpa-school {
        margin-left: 35%;
    }
}
.sudoku {
    margin-left: 21vw;
    margin-top: 14vh;
    font-size: 5vw;
    font-family: 'Courier New', monospace;
}

.container {
    display: table;
    margin-left: 16vw;
    width: 80%;
    text-align: right;
    margin-bottom: -80px;
}

.sudoku-desc {
    display: table-cell;
    font-family: 'Courier New', monospace;
    font-size: .95vw;
    width: 28vw;
    text-align: justify;
}

.generate-grid {
    display: table-cell;
    white-space:pre;
    font-size: 1.83vw;
}

.solve-grid {
    display: table-cell;
    white-space:pre;
    font-size: 1.83vw;
}

/* Button design taken from Retro Buttons Website by b.Stayles */
.sudoku-button {
    position: relative;
	background: #7C7C7C;
	border-bottom: 6px inset rgba(0,0,0,.5);
	border-left: 6px inset rgba(0,0,0,.5);
	border-right: 6px inset rgba(255,255,255,.5);
	border-top: 6px inset rgba(255,255,255,.5);
	color: white;
	cursor: pointer;
	font-size: 1.4vw;
	margin-left: 5vw;
    margin-top: 3vh;
	width: 8vw;
	padding: .5vw;
	text-transform: uppercase;
    font-family: 'VT323', monospace ;
}

.solve-button:hover {
    background: #BCBCBC;
}

.easy-button {
	background: #0000BC;
    margin-left: 49%;
}

.easy-button:hover {
    background: #0000FC;
}

.medium-button {
	background: #005800;
}

.medium-button:hover {
    background: #006800;
}

.hard-button {
    background: #881400;
}

.hard-button:hover {
    background: #A81000;
}

@media (max-width: 1900px) {
    .sudoku-desc {
        padding-right: 5vw;
    }

    .generate-grid {
        padding-right: 5vw;
    }
}

@media (max-width: 800px) {
    .sudoku { 
        margin-left: 42vw;
        font-size: 10vw;
    }

    .container {
        table-layout: fixed;
        width: 250vw;
        margin-left: 28vw;
    }

    .sudoku-desc {
        font-size: 3vw;
        padding-right: 10vw;
        width: 60vw;
    }

    .generate-grid {
        font-size: 35px;
        padding-right: 10vw;
        margin-right: 10vw;
    }
    
    .solve-grid {
        font-size: 35px;
        padding-right: 10vw;
        margin-right: 10vw;
    }

    .sudoku-button {
        position: absolute;
        font-size: 7vw;
        width: 35vw;
        margin-top: 15vh;
    }

    .easy-button {
        left: 55vw;
        top: 80vh;
    }

    .medium-button {
        left: 145vw;
        top: 80vh;
    }

    .hard-button {
        left: 190vw;
        top: 80vh;
    }

    .solve-button {
        left: 235vw;
        top: 80vh;
    }
}

@media (max-height: 500px) {
    .sudoku { 
        margin-top: 17vh;
        margin-left: 26.5vw;
    }
    
    .container {
        margin-left: 22vw;
    }

    .sudoku-button { 
        left: 6vw;
        font-size: 1.2vw;
    }
}
.image-translation {
    font-size: 50px;
    margin-left: 30%;
    margin-top: 40vh;
    margin-bottom: 40vh;
}

@media (max-width: 800px) {
    .image-translation {
        margin-top: 20vh;
    }
}
.maze-solver {
    font-size: 50px;
    margin-left: 30%;
    margin-top: 40vh;
}

@media (max-width: 800px) {
    .maze-solver {
        margin-top: 20vh;
    }
}
.yosemiteandme {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17vh;
    max-height: 40vh;
    border-radius: 100%;
}

.info {
    font-size: 3vh;
    font-family: 'Courier New', monospace;
    margin-left: 30vw;
    white-space: pre;
}

.section-title {
    margin-bottom: 2vh;
    margin-top: 2vh;
}

@media (max-width: 1200px) {
    .info {
        margin-left: 20vw;
    }
}

@media (max-width: 1000px) {


    .info {
        margin-left: 10vw;
    }
}

@media (max-width: 600px) {
    .yosemiteandme {
        margin-top: 10vh;
        margin-left: 22vw;
        max-height: 30vh;
    }

    .info {
        font-size: 1.6vh;
        margin-left: 3vw;
    }
}
