/* Design taken from CSS Scan by Michael McMillan*/

.page-link {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  letter-spacing: .1vw;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: .25vw solid;
  margin-right: .8vw;
  box-shadow: .01vw .01vw 0vw 0vw, .02vw .02vw 0vw 0vw, .1vw .1vw 0vw 0vw, .2vw .2vw 0vw 0vw, .3vw .3vw 0vw 0vw;
  position: relative;
  margin-bottom: .8vh;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.page-link:active {
  box-shadow: 0vw 0vw 0vw 0vw;
  top: .3vw;
  left: .3vw;
}

@media (min-width: 800px) {
  .page-link {
    padding: .4vh .5vw;
  }
}

@media (max-width: 800px) {
  .page-link {
    font-size: 2vw;
  }
}